import Dropdown from 'react-bootstrap/Dropdown';
import '../../../styles/MarkerPopup.css'
import { execScript } from '../../../http/complexesApi.js';
import { notify } from '../../helpers.js';

const DropdownScripts = (props) => {
    // console.log(props.complex)
    const scripts = []
    props.complex.child.map(c => {
      c.scripts.map(s => {
        if (s.type === '0' && s.description === 'map') {
          // scripts.push({hostid: c.hostid, hostname: c.name.split(' / ')[1], id: s.id, name: s.name})
          scripts.push({id: s.id, complex: c, name: s.name})
        }
        
      })
    })
    if (scripts.length === 0) return null
    return (
        <Dropdown>
          <Dropdown.Toggle variant="warning" id="dropdown-basic">
            <span className='fa fa-terminal'></span>
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            {/* {
              scripts.map(scr => {
                // console.log(scr)
                const t = () => {
                  execScript(scr.hostid, scr.id)
                }
                return <Dropdown.Item key={scr.hostname} onClick={t}>{scr.name} ({scr.hostname})</Dropdown.Item>
              })
            } */}
            {
              scripts.map((scr) => {
                const t = () => {
                  console.log(scr)
                  notify(props.notify, 'info', {head: scr.complex.name, body: 'Выполнение команды, дождитесь завршения'})
                  execScript(scr.complex, scr, (type, message) => {
                    console.log(type, message)
                    notify(props.notify)
                    notify(props.notify, type, message)
                  })
                  
                }
                return <Dropdown.Item key={scr.id} onClick={t}>{scr.name}-{scr.complex.name.split(' / ')[1]}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownScripts;