import React, { useContext } from "react";
import { Marker } from "react-leaflet";
import { getIconVehicle, getSVGIconVehicle } from "../helper";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import MarkerPopup from "./MarkerPopup";
// import MarkerTooltip from "./MarkerTooltip";


// import { ComplexesFilterContext } from "../../../context";

export const MarkersVehicle = props => {
    // console.log(props.vehicles)
    // const { complexesFilterPayload } = useContext(ComplexesFilterContext)

    if (!props.vehicles) {
        return <></>
    }



    const markers = props.vehicles.map(vehicle => {
        var lat = vehicle.items.find(item => item.key_.includes('_lat'))?.lastvalue
        var lon = vehicle.items.find(item => item.key_.includes('_lon'))?.lastvalue
        if (!lat && vehicle.inventory.location_lat) {
            lat = vehicle.inventory.location_lat
        }
        if (!lon && vehicle.inventory.location_lon) {
            lon = vehicle.inventory.location_lon
        }
        if (!lat || !lon) {
            return null;
        }

        // console.log([lat, lon])
        // if (complexesFilterPayload.severity && complexesFilterPayload.severity > 0) {
        //     const triggers = (complex.triggers.find((t) => parseInt(t.priority, 10) >= complexesFilterPayload.severity)) ? true : false
        //     if (!triggers) { 
        //         return null
        //     }
        // }
        
        // const triggetMaxPriority = getTriggerMaxPriority(complex)
        return (
            <Marker
                position={[
                    lat,
                    lon
                    
                ]}
                icon={getIconVehicle(vehicle)}
                key={vehicle.hostid}
                data={vehicle.hostid}
                
            >
                <MarkerPopup vehicle={vehicle} key={`popup_${vehicle.hostid}`} />
                {/* {
                    complex.macros.find(item => item.macro === '{$POWER}')?.value === 'BAT' ?
                    <MarkerTooltip complex={complex} key={`tooltip_${complex.hostid}`} />
                        :
                        null

                } */}
            </Marker>
        );
    });
    return markers;
}

