export function notify(control, type, message) {
    console.log(type, message)
    switch (type) {
        case 'alert':
            control.alert(message.head, message.body)
            break;
        case 'success':
            control.success(message.head, message.body)
            break;
        case 'info':
            control.info(message.head, message.body)
            break;
        case 'warning':
            control.warning(message.head, message.body)
            break;
        case 'custom':
            control.custom(message.head, message.body)
            break;
        default:
            control.clear()
    }
}