import { $authHost } from "."


export default async function getStat(start, end) {
    // console.log(`start: ${start} end: ${end}`)
    try {
        const { data } = await $authHost.post('/api/statistic', {start, end})
        
        return data
    } catch(e) {
        console.log(e)
    }
}

export async function getXlsx(start, end) {
    // console.log(`start: ${start} end: ${end}`)
    try {
        const data = await $authHost.post('/api/statistic/xlsx', {start, end}, {responseType: 'blob',})
        console.log(data)
        return data
        
 
    } catch(e) {
        console.log(e)
    }
    
}


export async function getSingleStat(start, end, host) {
    // console.log(`start: ${start} end: ${end}`)
    const { data } = await $authHost.post('/api/statistic', {start, end})
    const result =  data.find(complex => complex.host === host)
    // console.log('in single ', data, result, host)
    return result
}