import { useEffect, useState } from 'react';
import { Accordion, Form, Row } from 'react-bootstrap';
import { getGraphs } from '../../../http/complexesApi.js'
import { OrbitProgress } from 'react-loading-indicators';
import { PhotoProvider, PhotoView } from 'react-photo-view';
const ComplexGraphs = props => {
    const [hostid, setHostid] = useState(null)
    const [graphs, setGraphs] = useState(null)
    const [loading, setLoading] = useState(true)


    var hosts = [props.complex]

    props.complex.child.map(child => {
        hosts = [...hosts, child]
    })
    // console.log(hosts)
    if (hosts.length > 0 && !hostid) {
        setHostid(hosts[0].hostid)
    }

    useEffect(() => {
        setLoading(true)
        async function fetchGraphs(id) {
            getGraphs(id).then(res => setGraphs(res)).catch(err => setTimeout(() => setHostid(id), 15 * 1000))
        }
        fetchGraphs(hostid)
    }, [hostid])

    useEffect(() => {
        // console.log(graphs)
        if (graphs && graphs.graphs.length > 0) {
            setLoading(false)

        }
    }, [graphs])
    return (

        <Form>
            <Row key={0} style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Select style={{ width: '80%' }} onChange={(e) => {
                    setHostid(e.target.value)
                    // console.log(e.target.value)
                }}>
                    {
                        hosts.map(c => {
                            return <option key={c.hostid} value={c.hostid}>{c.name.split('/')[1]}</option>
                        })
                    }
                </Form.Select>
            </Row>
            <hr style={{ margin: '4px' }} />
            <Row key={1} style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                    {
                        loading ?
                            <div style={{ width: "100%", height: "200px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                                <OrbitProgress color="green" size="medium" text="" textColor="" />
                            </div>
                            : graphs.graphs.map(graph => {
                                // console.log(graph)
                                const t = `data:image/jpeg;base64,${graph[1]}`
                                // console.log(graph[0])
                                return (
                                    <div key={graph[0]}>
                                        
                                        <PhotoProvider>
                                            <PhotoView key={graph[0] + "ph"} src={t}>
                                                <img key={graph[0] + "img"} src={t} width={'100%'} alt='' />
                                            </PhotoView>
                                        </PhotoProvider>
                                        {/* <img key={graph[0] + 'img'} src={t} /> */}
                                        <hr key={graph[0] + "hr"} style={{ margin: '4px' }} />
                                    </div>

                                )
                            })
                    }
                </div>


            </Row>

        </Form>

    )

}

export default ComplexGraphs