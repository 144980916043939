import { itemMutator, itemsNormalizer } from '../../../utils/converters';
import { Card } from 'react-bootstrap';
import '../../../styles/ModalContent.css';

const ComplexData = props => {


    // var active_tabs = [props.complex.hostid]
    // props.complex.child.map(item => {
    //     active_tabs = [item.hostid, ...active_tabs]
    // })

    var types_names = new Set()
    var types = {}
    props.complex.items.map(item => {
        var t
        t = item.name.indexOf('.') !== -1 ? item.name.split('.')[0] : t = 'Данные БД'
        // active_tabs = [t, ...active_tabs]
        types_names.add(t)
        types[t] = !types[t] ? [item] : [item, ...types[t]]
    })
    types_names = Array.from(types_names).sort((a, b) => a.localeCompare(b))

    // console.log(props.complex.child.sort((a, b) => a.name.split(' / ')[1].localeCompare(b.name.split(' / ')[1])))
    return (
   
            <div className="d-flex flex-wrap">
                {
                    types_names.map(type => {
                        types[type].sort((a, b) => {
                            if (a.name.split('.')[1] > b.name.split('.')[1]) { return 1 }
                            if (a.name.split('.')[1] < b.name.split('.')[1]) { return -1 }
                            return 0

                        })

                        return (

                            <Card key={type} className='d-flex m-2 border border-warning'>
                                <Card.Header className='card-header d-flex justify-content-center p-0 bg-dark text-white' >{type}</Card.Header>
                                <Card.Body className='d-flex p-0'>
                                    {/* <Card.Title>Special title treatment</Card.Title> */}
                                    <div className='p-2'>
                                        {
                                            types[type].map(item => {
                                                const item_converted = itemMutator(item)
                                                if (item.status === "0") {
                                                    return (

                                                        <div key={item.itemid} className='d-flex'>
                                                            <span className='card-body'  style={{ paddingRight: 5 }}><b>{item.name.indexOf('.') !== -1 ? item.name.split('.')[1] : item.name}:</b></span>
                                                            <span className='card-body'  style={{ maxWidth: 250 }}>{item_converted.lastvalue} {item_converted.units}</span>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }

                {
                    props.complex.child.sort((a, b) => a.name.split(' / ')[1].localeCompare(b.name.split(' / ')[1])).map(host => {
                        return (

                            <Card key={host.name.split('/')[1]} className='d-flex m-2 border border-warning'>
                                <Card.Header as="h6" className='d-flex justify-content-center p-1 bg-dark text-white'>{host.name.split('/')[1]}</Card.Header>
                                <Card.Body className='d-flex p-0'>
                                    {/* <Card.Title>Special title treatment</Card.Title> */}
                                    <div className='p-2'>
                                        {
                                            itemsNormalizer(host).items.map(item => {
                                                if (item.status === "0") {
                                                    return (
                                                        <div key={item.itemid} className='d-flex'>
                                                            <span className='card-body' style={{ paddingRight: 5 }}><b>{item.name}:</b></span>
                                                            <span className='card-body' style={{ maxWidth: 250 }}>{item.lastvalue.substring(0, 100)} {item.units}</span>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </div>

    )


}

export default ComplexData