import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { getAcknowldgedTriggerUrl, getBitrixNote, getNote, violationsFixations } from "../helper";
import React from "react"
import { getProblems } from "../helper";
import { useContext } from "react";
import { CurrentComplexContext, UserContext } from "../../../context";
import '../../../styles/MarkerPopup.css'
import '../../../styles/MarkerPopup_2x.css'
import DropdownLinks from "./DropdownLinks";
import DropdownScripts from "./DropdownScripts";
import DropdownBitrix from "./DropdownBitrix";
import { popup } from "leaflet";

const MarkerPopup = props => {
    // console.log(props.complex)
    const { violations, fixations } = violationsFixations(props.complex);
    const { setCurrentComplexPayload } = useContext(CurrentComplexContext);
    const { userPayload } = useContext(UserContext)
    const { roles } = userPayload


    
    const infoClick = () => {
        setCurrentComplexPayload({ modal: true, class: 'crossroad', complex: props.complex })
    }


    var problems = getProblems(props.complex)
    var notes = getNote(props.complex)
    var bitrixNotes = getBitrixNote(props.complex)
    var acknowledge_urls = []
    var acknowledge_url = getAcknowldgedTriggerUrl(props.complex)
    if (acknowledge_url) { acknowledge_urls.push({sn: props.complex.name.split(' / ')[1], url: acknowledge_url})}
    // console.log(acknowledge_urls)
    props.complex.child.map(host => {
        problems = [...getProblems(host), ...problems]
        notes = [...notes, ...getNote(host)]
        bitrixNotes = [...bitrixNotes, ...getBitrixNote(host)]
        acknowledge_url = getAcknowldgedTriggerUrl(host)
        if (acknowledge_url) { acknowledge_urls.push({sn: host.name.split(' / ')[1], url: acknowledge_url})}
        // const note = host.items.find(item => item.key_ === 'notes')?.lastvalue
        // if (note && note !== 'Нет данных') {
        //     notes.push([host.name.split(' / ')[1], note])
        // }
    })
    // console.log(notes)
    // problems = problems.sort((a,b) => {
    //     return a[1].split('/')[1].localeCompare(b[1].split('/')[1])
    // })

    // props.complex.vsc.map(vsc => {
    //     problems = [...getProblems(vsc), ...problems]
    // })
    // console.log(`fixations ${props.complex.name}`, fixations)
    return (
        <Popup maxWidth={550}>
            <div key={'popup_header'} className='popup_header'>
                <span style={{ fontWeight: 600, textAlign: 'center', width: '100%', maxWidth: '400px' }}>{props.complex.name} </span>
            </div>
            <div key={'popup_line_1'} style={{ display: "flex", flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap'}}>
                <span className="popup_hr_line" >-</span>
                <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => window.open(`https://yandex.ru/maps/?whatshere[point]=${props.complex.inventory.location_lon},${props.complex.inventory.location_lat}&whatshere[zoom]=17`)}>{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_hr_text">Координаты</span>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span  className="popup_hr_text">Проездов</span>
                <span  className="popup_hr_line">-</span>
                <span  className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span  className="popup_hr_line" style={{ paddingBottom: '2px'}}>-</span>
            </div>

            <div key={'popup_line_2'} style={{ display: "flex", flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap'}}>
                <span className="popup_hr_line" >-</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_hr_text">Время</span>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span className="popup_hr_text">Нарушений</span>
                <span className="popup_hr_line">-</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span>
                <span className="popup_hr_line" style={{ paddingBottom: '2px'}}>-</span>
            </div>
            {/* <hr style={{ margin: '5px' }} />
            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_fixations">Проездов</span>
                <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span> 
                <span className="popup_body_badge popup_body_badge_violations">Нарушений</span>
                <br />
            </div> */}
            
            {
                notes.length === 0 && bitrixNotes.length === 0 ? null :
                    <>
                        <div key={'popup_line_3'} style={{ display: "flex", flexDirection: 'row'}}>
                            <hr className="popup_hr" style={{ width: '100%'}}/>
                            <span className="popup_hr_text">Заметки</span>
                            <hr className="popup_hr" style={{ width: '5px'}}/>
                        </div>
                        { notes.map(note => {
                                return(<div key={note} className='popup_body'>
                                    <span className="popup_body_badge popup_body_badge_alerts_host" style={{ color: 'red', fontStyle: 'italic' }}><b>Zabbix</b></span>
                                    <span className="popup_body_badge popup_body_badge_alerts_host" style={{ whiteSpace: 'nowrap' }}><b>{note[0]}</b></span>
                                    <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: 'purple' }}>{note[1]}</span>
                                </div>)
                        })}
                        { bitrixNotes.map(note => {
                                return(<div key={note} className='popup_body'>
                                    <span className="popup_body_badge popup_body_badge_alerts_host" style={{ color: 'blue', fontStyle: 'italic' }}><b>Bitrix</b></span>
                                    <span className="popup_body_badge popup_body_badge_alerts_host" style={{ whiteSpace: 'nowrap' }}><b>{note[0]}</b></span>
                                    <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: 'purple' }}>{note[1]}</span>
                                </div>)
                        })}
                    </>
            }
            <div key={'popup_line_4'} style={{ display: "flex", flexDirection: 'row'}}>
                <hr className="popup_hr" style={{ width: '100%'}}/>
                <span className="popup_hr_text">Проблемы</span>
                <hr className="popup_hr" style={{ width: '5px'}}/>
            </div>
            {
                problems.length === 0
                    ?
                    <div key={'popup_problems'} className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                <span className="popup_body_badge popup_body_badge_alerts_host"><b>{element[1].split('/')[1]}</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                            </div>

                        )
                    })
            }
            <hr style={{ width: '100%', margin: '6px'}}/>
            
            <div key={'popup_footer'} className='popup_footer'>
                {roles && !(roles.includes('operator')) ?
                    <div style={{ display: 'flex'}}>
                        <DropdownLinks  complex={props.complex}/>
                        <DropdownScripts complex={props.complex} notify={props.notify}/>
                        {/* <DropdownBitrix urls={acknowledge_urls}/> */}
                    </div>
                    : null
                }
                <div style={{ display: 'flex'}}>
                {roles && !(roles.includes('operator')) ?
                    // <div style={{ display: 'flex'}}>
                    <DropdownBitrix urls={acknowledge_urls}/>
                        // <Button size="sm" variant="outline-success" href={acknowledge_url} title="Ссылка на внеплановую задачу в Битрикс"> <i style={{ paddingRight: 3}} class="fa fa-screwdriver-wrench"></i> </Button>
                    // </div>
                    : null
                }
                {props.complex.bitrix.regular_task ?
                    <Button size="sm" variant="outline-success" onClick={infoClick} title="Сcылка на регламентную задачу в Битрикс"> <i style={{ paddingRight: 2, width: '25px'}} class="fa fa-broom"></i> </Button>
                    : null
                }
                
                <Button size="sm" variant="outline-primary" onClick={infoClick} > 
                    <svg width="28px" height="15px" viewBox="0 3 48 48">
                        <circle fill="#2196F3" cx="24" cy="24" r="21"/>
                        <rect x="22" y="22" fill="#ffffff" width="4" height="11"/>
                        <circle fill="#ffffff" cx="24" cy="16.5" r="2.5"/>
                    </svg>
                </Button>
                </div>
            </div>
        </Popup>
    )
}


export default MarkerPopup;
