import Dropdown from 'react-bootstrap/Dropdown';
import '../../../styles/MarkerPopup.css'
import { execScript } from '../../../http/complexesApi.js';
import { notify } from '../../helpers.js';

const DropdownScripts = (props) => {
    if (props.complex.scripts.length === 0) return null
    return (
        <Dropdown>
          <Dropdown.Toggle variant="warning" id="dropdown-basic">
            <span className='fa fa-terminal'></span>
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            {
              props.complex.scripts.filter(s => s.description === 'map').map((scr) => {
                const t = () => {
                  console.log(scr)
                  notify(props.notify, 'info', {head: props.complex.name, body: 'Выполнение команды, дождитесь завршения'})
                  execScript(props.complex, scr, (type, message) => {
                    console.log(type, message)
                    notify(props.notify)
                    notify(props.notify, type, message)
                  })
                  
                }
                return <Dropdown.Item key={scr.id} onClick={t}>{scr.name}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownScripts;