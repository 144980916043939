import { MapContainer } from "react-leaflet";
import ModalInfo from "../components/modals/ModalInfo";
import ModalSearch from "../components/modals/ModalSearch";
import ModalStat from "../components/modals/ModalStat";
import ModalUsers from "../components/modals/ModalUsers";
import ControlLayer from "../components/layers/ControlLayer";
import Control from 'react-leaflet-custom-control'
import { Button, ToggleButton } from "react-bootstrap";
import "leaflet/dist/leaflet.css";
import "leaflet-extra-markers";
import "font-awesome/css/font-awesome.css";
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import '../styles/MapPage.css'
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext, ComplexesFilterContext } from "../context";
import { useSearchParams } from "react-router-dom";
import NotificationControl from '../libs/NotificationControl/NotificationControl'


const MapPage = () => {
    const [map, setMap] = useState(null);
    const [modalSearchVisibility, setModalSearchVisibility] = useState(false)
    const [modalStatVisibility, setModalStatVisibility] = useState(false)
    const [modalUsersVisibility, setModalUsersVisibility] = useState(false)
    const notify = useRef()
    const [searchParams, setSearchParams] = useSearchParams();
    const { userPayload } = useContext(UserContext)
    const { complexesFilterPayload, setComplexesFilterPayload } = useContext(ComplexesFilterContext)
    const { roles } = userPayload



    const closeModal = () => {
        setModalSearchVisibility(false)
        setModalStatVisibility(false)
        setModalUsersVisibility(false)
    }
    const hostSelected = (host, layer) => {
        localStorage.setItem(layer, 1)
        // localStorage.setItem('layer_speed', 1)
        // localStorage.setItem('layer_crossroad', 1)
        // localStorage.setItem('layer_deact', 1)
        localStorage.setItem('severity', 0)
        setComplexesFilterPayload({...complexesFilterPayload, severity: 0})
        console.log(map)
        setModalSearchVisibility(false)
        map.flyTo([host.inventory.location_lat, host.inventory.location_lon], 13, {
            duration: 1, // Animation duration in milliseconds
            easeLinearity: 0.7 // Adjust animation smoothness
        });
        setTimeout(() => {
            map.eachLayer(function (layer) {
                if (layer.options.data === host.hostid) {
                    layer.openPopup()
                } else if (layer.options.pane) {
                    var childs = []
                    try {
                        childs = layer.getAllChildMarkers()
                    } catch {
                        
                    }
                    const complex = childs.find(child => child.options.data === host.hostid)
                    if (complex) {
                        layer.zoomToBounds()
                        setTimeout(() => {
                            complex.openPopup()
                        }, 500)
                    }
                }
            })   
        }, 1500)   
    }
    const severities = [
        { level: 0, color: "#009549", name: "all"},
        { level: 1, color: "#28a1d7", name: "info"},
        { level: 2, color: "#f5bb3a", name: "warn"},
        { level: 3, color: "#ef9227", name: "awerage"},
        { level: 4, color: "#d63a23", name: "high"},
        { level: 5, color: "#a23337", name: "disaster"},
    ]

    

    useEffect(() => {
        if(localStorage.getItem('severity') === null) {
            localStorage.setItem('severity', 0)
        }
    }, [])

    useEffect(() => {
        if (map && searchParams.get('hostid')) {
            setTimeout(() => {
                hostSelected({
                    hostid: searchParams.get('hostid'),
                    inventory: {
                        location_lat: searchParams.get('lat'),
                        location_lon: searchParams.get('lon')
                    }
                })
            }, 1000)
            
        }
    }, [map])

    return (
        <div className="map-page">
            <MapContainer
                center={[42.65, 48.3]}
                zoom={9}
                scrollWheelZoom={true}
                attributionControl={false}
                ref={setMap}
            >
                <ControlLayer notify={notify.current} ccc={123}/>
                <Control position='bottomright' style>
                    <Button className='btn_control' variant="light" onClick={() => {
                        setModalSearchVisibility(true)
                    }} >
                        <FontAwesomeIcon icon={icon({ name: 'search' })} />
                    </Button>
                    <Button className='btn_control' variant="light" style={{ margin: "5px" }} onClick={() => {
                        setModalStatVisibility(true)
                    }} >
                        <FontAwesomeIcon icon={icon({ name: 'chart-bar' })} />
                    </Button>
                    {roles && roles.includes('admin') ?
                    <Button className='btn_control' variant="light" style={{ margin: "5px" }} onClick={() => {
                        setModalUsersVisibility(true)
                    }} >
                        <FontAwesomeIcon icon={icon({ name: 'user' })} />
                    </Button>
                    : null}
                </Control>
                
                <Control position='bottomleft' >
                    <div style={{ padding: "5px", background: "white", justifyContent: 'center', alignItems: 'center', display:'flex', borderRadius: '4px', border: '2px solid black' }}>
                        {/* <div style={{ marginBottom: "2px" }}>Уровень проблемы (0-5)</div> */}
                        {/* <div style={{ display: "flex", justifyContent: "spaceBetween", padding: "5px", gap: "5px" }}> */}
                            {/* <div style={{ background: "#009549", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 0 </div>
                            <div style={{ background: "#28a1d7", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 1 </div>
                            <div style={{ background: "#f5bb3a", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 2 </div>
                            <div style={{ background: "#ef9227", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 3 </div>
                            <div style={{ background: "#d63a23", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 4 </div>
                            <div style={{ background: "#a23337", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 5 </div> */}
                        {
                            severities.map(s => {
                                return <ToggleButton
                                // className="mb-2"
                                style={{ width: "20px", height: "20px", borderRadius: "50%", textAlign: "center", 
                                    "--bs-btn-border-width": '5px',
                                    "--bs-btn-border-color": `${s.color}`,
                                    "--bs-btn-active-bg": `${s.color}`,
                                    "--bs-btn-active-border-color": `${s.color}`,
                                    marginBottom: '2px'
                                    }}
                                key={s.name}
                                id={s.name}
                                type="checkbox"
                                variant="outline-success"
                                checked={(complexesFilterPayload.severity <= s.level) ? true : false}
                                value="1"
                                onChange={(e) => {
                                    setComplexesFilterPayload({...complexesFilterPayload, severity: s.level})
                                    localStorage.setItem('severity', s.level)
                                }   }                         
                                ></ToggleButton>
                            })
                        }
                        
                           
      
                        {/* </div> */}

                    </div>
                </Control>
                <NotificationControl 
                    ref={notify} 
                    timeout={30000}
                    className={'modern'}
                />
            </MapContainer>
            <ModalInfo />
            <ModalSearch visible={modalSearchVisibility} close={closeModal} select={hostSelected} />
            <ModalStat visible={modalStatVisibility} close={closeModal}/>
            {roles && roles.includes('admin') ? <ModalUsers visible={modalUsersVisibility} close={closeModal}/> : null}
            
            
        </div>
    )
}

export default MapPage