function itemsNormalizer(complex) {
  complex.items.forEach(item => {
    item = itemMutator(item)
  })
  return complex
}



function itemMutator(item) {

  // console.log(item.lastvalue);
  if (item.key_ === "GetStateAndEvents") {
    item.lastvalue = item.lastvalue.slice(0, 40) + "...";
  }

  if (item.lastvalue === "") {
    item.lastvalue = "Нет данных";
  }
  

  let name = '';
  let lastvalue = '';
  let units = '';

  switch (item.units) {
    case '':
      lastvalue = item.lastvalue;
      name = item.name;
      units = item.units;
      break;

    case 'uptime':
    case 's':
      lastvalue = uptimeToString(item.lastvalue)
      name = item.name;
      units = '';
      break;

    case 'unixtime':
      lastvalue = unixTimeToString(item.lastvalue)
      name = item.name;
      break;
    
    case 'bps':
      switch (item.lastvalue.length) {
        case 1:
          lastvalue = 0
          units = 'Mбит/c';
          break;
        case 8:
          lastvalue = 10
          units = 'Mбит/c';
          break;
        case 9:
          lastvalue = 100
          units = 'Mбит/c';
          break;
        case 10:
          lastvalue = 1
          units = 'Гбит/c';
          break;
        default:
          lastvalue = item.lastvalue;
          units = 'бит/c';
          break;
      }
      
      name = item.name;
      break;

    default:
      lastvalue = item.lastvalue;
      name = item.name;
      units = item.units;
      break;
  }

  // if (item.key_c === 'gpio3_status' || item.key_c === 'gpio3_status') {
  //   lastvalue = lastvalue === '0' ? 'OK' : 'FAILED';
  // }


  item.lastvalue = lastvalue
  item.units = units
  item.name = name

  return item

}







function triggerInfo(trigger) {
  const name = trigger.problem.tags.find((tag) => tag.tag === "name");
  const baseTime = trigger.problem.tags.find((tag) => tag.tag === "base");

  if (name) {
    return {
      name: name.value,
      duration: uptimeToString(
        Math.floor(Date.now() / 1000) -
        parseInt(trigger.lastchange) +
        parseInt(baseTime.value)
      ),
    };
  } else {
    return {
      name: trigger.description,
      duration: uptimeToString(
        Math.round(new Date().getTime() / 1000) - trigger.lastchange
      ),
    };
  }
}


function uptimeToString(uptime) {
  let totalSeconds = uptime;
  let days = Math.floor(totalSeconds / 86400);
  let hours = Math.floor((totalSeconds - days * 86400) / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600 - days * 86400) / 60);
  let seconds = Math.floor(totalSeconds - hours * 3600 - days * 86400 - minutes * 60);
  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return `${seconds}с`
      } else {
        return `${minutes}м ${seconds}с`
      }
    } else {
      return `${hours}ч ${minutes}м`
    }
  } else {
    return `${days}д ${hours}ч`
  }
}

function unixTimeToString(unixtime) {
  let milliseconds = unixtime * 1000;
  let dateObject = new Date(milliseconds);
  return dateObject.toLocaleString();
}

function skatTimeToString(time) {
  let dateObject = new Date(time);
  let year = dateObject.getUTCFullYear()
  let month = dateObject.getUTCMonth() + 1
  let day = dateObject.getUTCDate()
  let hours = dateObject.getUTCHours()
  let minutes = dateObject.getUTCMinutes()
  let seconds = dateObject.getUTCSeconds()
  let ms = dateObject.getUTCMilliseconds()
  return(`${hours}:${minutes}:${seconds}.${ms} ${day}.${month}.${year}`)
}


export {
  uptimeToString, unixTimeToString, triggerInfo, itemsNormalizer, skatTimeToString, itemMutator
}