import { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { getGraphs } from '../../../http/complexesApi.js'
import { OrbitProgress } from 'react-loading-indicators';
import { PhotoProvider, PhotoView } from 'react-photo-view';
const ComplexGraphs = props => {
    const [graphs, setGraphs] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchGraphs(id) {
            setGraphs(await getGraphs(id))
        }
        fetchGraphs(props.complex.hostid)
    }, [props])

    useEffect(() => {
        // console.log(graphs)
        if (graphs && graphs.graphs.length > 0) {
            setLoading(false)
        
        }
    }, [graphs])
    return (
        // <Accordion alwaysOpen>
        <div>
            {
                loading ? 
                <div style={{ width: "100%", height: "200px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center"}}>
                    
                    <OrbitProgress color="green" size="medium" text="" textColor="" />
                      
                    
                </div>
                : graphs.graphs.map(graph => {
                    // console.log(graph)
                    const t = `data:image/jpeg;base64,${graph[1]}`
                    // console.log(graph[0])
                    return (
                        <div key={graph[0]}>
                            <hr key={graph[0] + "hr"} style={{ margin: '2px'}}/>
                            <PhotoProvider>
                                <PhotoView key={graph[0] + "ph"} src={t}>
                                    <img key={graph[0] + "img"} src={t} width={'100%'} alt='' />
                                </PhotoView>
                            </PhotoProvider>
                        </div>
                        
                    )
                })
            }
        </div>
        // </Accordion>
    )


    // return (
    //     <div>
    //         <div className="trigger-list">
    //             {
    //                 props.complex.triggers.map(trigger => {
    //                     return (
    //                         <div className="trigger-list__item" key={`${trigger.triggerid}_${props.complex.hostid}`}>
    //                             <div>{triggerInfo(trigger).name}</div>
    //                             <div>{triggerInfo(trigger).duration}</div>
    //                         </div>

    //                     )
    //                 })
    //             }
    //         </div>
    //         <div className="params-list">
    //             {
    //                 itemsNormalizer(props.complex).items.map(item => {
    //                     return (
    //                         <div className="params-list__item" key={`${item.key_}_${props.complex.hostid}`}>
    //                             <div>{item.name}</div>
    //                             <div>{item.lastvalue}&nbsp;{item.units}</div>
    //                         </div>

    //                     )
    //                 })
    //             }

    //         </div>
    //     </div>
    // )

}

export default ComplexGraphs