import { getProblems } from "../../markers/helper";
import { Card } from 'react-bootstrap';
import '../../../styles/ModalContent.css';

const ComplexChilds = props => {
    return (
        <div className="d-flex flex-wrap">
            {
                props.childs.sort((a, b) => a.name.split(' / ')[1].localeCompare(b.name.split(' / ')[1])).map(host => {
                    const note = host.items.find(item => item.key_ === 'notes')
                    console.log(note)
                    return (
                        <Card key={host.name.split('/')[1]} className='d-flex m-2'>
                            <Card.Header className='card-header d-flex justify-content-center p-1 bg-dark text-white' >{host.name.split('/')[1]}</Card.Header>
                            
                                {
                                    note && note.lastvalue !== 'Нет данных' ?
                                    <Card.Subtitle className="text-muted justify-content-center d-flex">
                                    {/* <div key='note' className='d-flex' > */}
                                                <span className='card-body' style={{ display:'flex', justifyContent:'center', width: '100%',color: "black", maxWidth: 250, backgroundColor: 'yellow', fontWeight: 'bold', fontSize: '11px' }} key={`${host.hostid}_0`}>{note.lastvalue}</span>
                                                {/* <hr style={{ margin: '1px'}}/> */}
                                    {/* </div> */}
                                    </Card.Subtitle>
                                    : null
                                }

                            
                            <Card.Body className='d-flex p-0 justify-content-center'>
                                <div className='align-self-ce nter p-1'>
                                    {
                                        host.triggers.length === 0 ?
                                            <div className='d-flex justify-content-center p-0'>
                                                 <span className='card-body' style={{ color: "green", maxWidth: 250, fontWeight: 'bold' }} key={`${host.hostid}_0`}>Работа в штатном режиме</span>
                                            </div>
                                            :
                                            getProblems(host).map(problem => {
                                               return (

                                                    <div key={problem} className='d-flex'>
                                                        <span className='card-body' style={{ color: problem[4], paddingRight: 5, textDecoration: 'underline' }}><b>{problem[2]}:</b></span>
                                                        <span className='card-body' style={{ color: problem[4], maxWidth: 250 }}>{problem[3]}</span>
                                                    </div>
                                                )
                                            })
                                    }
                                    
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })
            }
        </div>
    )


}

export default ComplexChilds