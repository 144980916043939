import Dropdown from 'react-bootstrap/Dropdown';
import '../../../styles/MarkerPopup.css'

const DropdownBitrix = (props) => {
    // const interfaces = props.complex.interfaces.filter((p) => p.type === '1')
    if (props.urls.length === 0) return null
    // console.log(props)
    return (
        <Dropdown>
          <Dropdown.Toggle variant="outline-success" id="dropdown-basic" title='Ссылки на внеплановые задачи в Битрикс'> <i style={{ paddingRight: 3}} className="fa fa-screwdriver-wrench"></i> 
            {/* <span className='fa fa-link'></span> */}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              props.urls.map((url) => {
                return <Dropdown.Item key={url.sn} href={url.url}>{url.sn}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownBitrix;