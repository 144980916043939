import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { getProblems, getAcknowldgedTriggerUrl, violationsFixations } from "../helper";
import React from "react";
import { useContext } from "react";
import { CurrentComplexContext, UserContext } from "../../../context";
// import DropdownLinks from "./DropdownLinks";
// import DropdownScripts from "./DropdownScripts";
import '../../../styles/MarkerPopup.css'

const MarkerPopup = props => {
    // console.log(props.complex)
    // const { violations, fixations } = violationsFixations(props.complex);
    // const { setCurrentComplexPayload } = useContext(CurrentComplexContext)
    // const { userPayload } = useContext(UserContext)
    // const { roles } = userPayload
    // const problems = getProblems(props.complex)
    // const acknowledge_url= getAcknowldgedTriggerUrl(props.complex)
    // const infoClick = () => {
    //     setCurrentComplexPayload({ modal: true, class: 'speed', complex: props.complex })
    // }

    const lat = props.vehicle.items.find(item => item.key_.includes('_lat'))?.lastvalue
    const lon = props.vehicle.items.find(item => item.key_.includes('_lon'))?.lastvalue
    const time = props.vehicle.items.find(item => item.key_.includes('_time'))?.lastvalue
    return (
        <Popup maxWidth={400} >

            <div className='popup_header'>
                <span style={{ fontWeight: 600 }}>{props.vehicle.name} </span>
            </div>
            <hr style={{ margin: '5px' }} />

            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => window.open(`https://yandex.ru/maps/?whatshere[point]=${lon},${lat}&whatshere[zoom]=17`)}>{lat}, {lon}</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date(parseInt(time) * 1000)).toLocaleString('ru')}</span>
                
                <br />
            </div>
            <hr style={{ margin: '5px' }} />
{/* 
            <hr style={{ margin: '5px' }} />
            <div className='popup_footer'>
              
                
            
                <Button size="sm" variant="primary" onClick={infoClick}> Подробно </Button>
            
            </div> */}

            {/* <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
                <br />
                <p />
                [{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}]
            </div>
            <div>
                <p />
                {(new Date()).toLocaleString('ru')}
            </div>
            <div style={{ fontSize: "12px" }}>
                <br />
                <span style={{ color: "green" }}>Проездов в течение дня: {fixations}</span>
                <br />
                <span style={{ color: "red" }}>Нарушений в течение дня: {violations} </span>
                <br />
            </div>
            <br />
            <div style={{ fontSize: "12px" }}>
                <ul>
                    {
                        problems.length === 0
                            ?
                            <li><span style={{ color: "green" }} key={0}>Работа в штатном режиме</span></li>
                            : problems.map(element => {
                                return (
                                    <li key={`${element.key_}_${props.complex.hostid}`}><span style={{ color: "red" }}> {element[0]} : {element[1]}</span></li>
                                )
                            })
                    }
                </ul>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "right" }}>
                <Button size="sm" variant="outline-primary" onClick={infoClick}>Подробно</Button>
            </div> */}
        </Popup>
    )
}


export default MarkerPopup;
