import { Card, Row, Form, Button, Col } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { signin } from '../http/userApi';
import { UserContext } from '../context';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const { setUserPayload } = useContext(UserContext)
    const navigate = useNavigate()
    const submit = async (login, password) => {
        try {
            const res = await signin(login, password)
            console.log(res)
            setUserPayload(
                {
                    ...res, isAuth: true, isAdmin: res.roles.includes('admin')
                }
            )
            navigate('/map')
            console.log('in login')
        } catch (e) {
            alert(e.response.data.message)
        }


    }
    return (
        <Row style={{fontFamily: 'Consolas', height: "calc(100vh - 80px)", display:"flex", alignItems:"center", '--bs-gutter-x': '0'}}>
        <Col lg={4} md={3} sm={2} xs={1}>
        </Col>

        <Col lg={4} md={6} sm={8} xs={10}>
            <Card>
            <Card.Header className='text-center'>
                Введите учетные данные
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formLogin">
                        <Form.Label column sm="3">
                        <span>Логин</span>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                type="text"
                                placeholder="логин"
                                onChange={e => setLogin(e.target.value)}
                                onKeyDown={e => { if (e.key === "Enter") { submit(login, password) } }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPassword">
                        <Form.Label column sm="3">
                        <span>Пароль</span>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control
                                type="password"
                                placeholder="пароль"
                                onChange={e => setPassword(e.target.value)}
                                onKeyDown={e => { if (e.key === "Enter") { submit(login, password) } }}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPassword">
                        <Col md="3" sm="0"></Col>
                        <Col md="6" sm="12">
                            <div style={{display:"flex", justifyContent:"center"}}>
                            <Button style={{width:"120px"}}
                                variant='primary'
                                onClick={e => {
                                    e.preventDefault();
                                    submit(login, password)
                                }}
                            >Вход</Button>
                            </div>
                        </Col>
                        <Col md="3" sm="0"></Col>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        </Col>
        <Col lg={4} md={3} sm={2} xs={1}>
            </Col>
                                        

        </Row>

    )
}

export default LoginForm