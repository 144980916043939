import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ComplexData from './ComplexData';
import ComplexViewSkatS from './ComplexViewSkatS';
import '../../../styles/ModalContent.css';
import { useState } from 'react';
import ComplexGraphs from './ComplexGraphs';

const ModalContentSpeed = props => {
    const [key, setKey] = useState('home');
    console.log(props.complex)
    const type = props.complex.tags.find((c) => c.tag === 'type')
    console.log(type)
    return (
        <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3"
            onSelect={(k) => setKey(k)}
            activeKey={key}
        >
            
            <Tab eventKey="home" title="Данные">
                <ComplexData complex={props.complex} />
            </Tab>

            {
                type.value === 'skat-s' || type.value === 'krechet' ?
                <Tab eventKey="view" title="Обзор">
                    <ComplexViewSkatS complex={props.complex} />
                </Tab>
                : null
            }
            <Tab eventKey="graphs" title="Графики">
                <ComplexGraphs complex={props.complex} />
            </Tab>
      
            {/* {
                ["codd", "uprdor", "camera_ovn" ].includes(props.complex.tags.find(tag => tag.tag === "type").value) ? 
                <Tab eventKey="stat" title="Статистика">
                    <ModalStat complex={props.complex}/>    
                </Tab>
                : null
            } */}

        </Tabs>
    )

}

export default ModalContentSpeed